import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";

export const formatDateOrRelative = (isoDate: string | Date) => {
  const currentDate = new Date();
  const inputDate = new Date(isoDate);

  const daysAgo = differenceInDays(currentDate, inputDate);
  const weeksAgo = differenceInWeeks(currentDate, inputDate);
  const monthsAgo = differenceInMonths(currentDate, inputDate);
  const yearsAgo = differenceInYears(currentDate, inputDate);

  if (daysAgo < 7) {
    if (daysAgo === 0) return "today";
    if (daysAgo === 1) return "yesterday";
    return `${daysAgo} days ago`;
  } else if (weeksAgo < 4) {
    if (weeksAgo === 1) return "last week";
    return `${weeksAgo} weeks ago`;
  } else if (monthsAgo < 12) {
    if (monthsAgo === 0) return "this month";
    if (monthsAgo === 1) return "last month";
    return `${monthsAgo} months ago`;
  } else {
    if (yearsAgo === 1) return "last year";
    return `${yearsAgo} years ago`;
  }
};
