import { useAppSelector } from "@/store/hooks";
// import { useRegenerateHistoryStudyMutation } from "@/store/modules/history/slice";
import { PLANS_ROUTE } from "@/templates/plans/plans.routes";
import { faArrowRight, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { App as AntApp, Card, Modal, Space, Spin, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { CustomInput } from "components/ui-kit/components/custom-input";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { formatDateOrRelative } from "logic/internals/utils/format-date-to-relative";
import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { History } from "store/modules/history/types";
import {
  useDeleteStudyMutation,
  useUpdateStudyDescriptionMutation,
} from "@/store/modules/studies/slice";
import { STUDY_ROUTE } from "templates/studies/study.routes";

export type HistoryCardProps = History & {
  isMobile?: boolean;
};

type HistoryCardInput = {
  description: string;
};

type ListItem = {
  label: string;
  value?: string | number | null | React.ReactNode;
};

type HistoryCardItemValue = string | string[] | number | undefined | null;

const HistoryCard: React.FC<HistoryCardProps> = ({
  id,
  createdAt,
  createdBy,
  description,
  studyStrategy,
  audiences,
  solution,
  problems,
  numberOfInterviews,
  researchGoal,
  planId,
  // status,
}) => {
  const { serifFont } = useExtraToken();

  const studyPlansList = useAppSelector((state) => state.studyPlans.data.studyPlansList);

  const studyPlan = useMemo(
    () => studyPlansList?.find((plan) => plan.id === planId),
    [planId, studyPlansList]
  );

  const [deleteStudy, { isSuccess: isDeleteSuccess, isLoading: isDeleting }] =
    useDeleteStudyMutation();

  const [updateStudyDescription, { isSuccess: isUpdateSuccess, isLoading: isUpdating }] =
    useUpdateStudyDescriptionMutation();

  // const [regenerateStudy] = useRegenerateHistoryStudyMutation();

  // Local State
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { message } = AntApp.useApp();

  // Hook Form Setup
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<HistoryCardInput>({
    defaultValues: {
      description: description || "",
    },
  });

  // Formatted Created At Date to Relative
  const formattedCreatedAt = useMemo(() => formatDateOrRelative(createdAt), [createdAt]);

  // Format List Item Label helper
  const formatListItemLabel = (label: string, value: HistoryCardItemValue): ListItem["label"] => {
    if (Array.isArray(value) && value.length > 1) return `${label}s`;

    return label;
  };

  // Format List Item Value helper
  const formatListItemValue = (value: HistoryCardItemValue): ListItem["value"] => {
    if (!value) return undefined;

    if (Array.isArray(value)) {
      return value.length > 1 ? (
        <ul style={{ paddingLeft: 16 }}>
          {value.map((item, i) => (
            <li key={`${item}-${i}`}>{item}</li>
          ))}
        </ul>
      ) : (
        value[0]
      );
    }

    return <Typography.Text style={{ fontSize: 12 }}>{value}</Typography.Text>;
  };

  // const studyFinishedStatus = ["ready", "done"];

  const audienceItem = useMemo(
    () => ({
      label: formatListItemLabel("Audience", audiences),
      value: formatListItemValue(audiences),
    }),
    [audiences]
  );

  // List Items
  const lisItems: ListItem[] = useMemo(() => {
    return [
      {
        label: formatListItemLabel("Created At", formattedCreatedAt),
        value: formatListItemValue(formattedCreatedAt),
      },
      {
        label: formatListItemLabel("Created By", createdBy),
        value: formatListItemValue(createdBy),
      },
      {
        label: formatListItemLabel("Type", studyStrategy),
        value: formatListItemValue(studyStrategy),
      },
      {
        label: formatListItemLabel("Problem", problems),
        value: formatListItemValue(problems),
      },
      {
        label: formatListItemLabel("Research Goal", researchGoal),
        value: formatListItemValue(researchGoal),
      },
      {
        label: formatListItemLabel("Solution", solution),
        value: formatListItemValue(solution),
      },
      {
        label: formatListItemLabel("# of Interviews", numberOfInterviews),
        value: formatListItemValue(numberOfInterviews),
      },
    ];
  }, [
    createdBy,
    formattedCreatedAt,
    numberOfInterviews,
    problems,
    researchGoal,
    solution,
    studyStrategy,
  ]);

  // Form Submit Handler
  const onSubmit: SubmitHandler<HistoryCardInput> = (data) => {
    // Don't submit if data hasn't changed
    if (data.description === description) {
      return setIsEditing(false);
    }
    updateStudyDescription({ studyId: id, description: data.description });
  };

  // onBlur handler
  const handleBlur = () => {
    reset();
    setIsEditing(false);
  };

  // Effect to set the description value
  useEffect(() => {
    setValue("description", description || "");
  }, [description, setValue]);

  // Effect to handle study delete success
  useEffect(() => {
    if (isDeleteSuccess) {
      message.success({
        content: "Study deleted successfully!",
        duration: 3,
      });
      setShowDeleteModal(false);
    }
  }, [isDeleteSuccess, message]);

  // Effect to handle study update success
  useEffect(() => {
    if (isUpdateSuccess) {
      message.success({
        content: "Study name updated successfully!",
        duration: 3,
      });
      setIsEditing(false);
    }
  }, [isUpdateSuccess, message]);

  return (
    <>
      {/* Message Api context holder */}

      {/* Delete study modal */}
      <Modal
        title={`Delete study?`}
        open={showDeleteModal}
        okText="Delete"
        okButtonProps={{ danger: true }}
        onOk={() => {
          deleteStudy({ studyId: id });
        }}
        confirmLoading={isDeleting}
        onCancel={() => setShowDeleteModal(false)}
      >
        <p>Are you sure you want to delete this study?</p>
      </Modal>

      <Card
        style={{ height: "100%", position: "relative" }}
        styles={{ body: { height: "100%", display: "flex", flexDirection: "column" } }}
      >
        {/* Open study icon */}

        {/* {studyFinishedStatus.includes(status) && ( */}
        <Link href={STUDY_ROUTE.getHref(id)} target="_blank" rel="noopener noreferrer">
          <AntIcon
            style={{
              position: "absolute",
              top: 16,
              right: 16,
            }}
            component={() => <FontAwesomeIcon size="lg" icon={faArrowRight} />}
          />
        </Link>
        {/* // )} */}

        {/* {status === "interviewing" && (
          <Typography.Text
            type="secondary"
            style={{
              position: "absolute",
              top: 24,
              right: 16,
            }}
          >
            Study is still running...
          </Typography.Text>
        )}

        {status === "failed" && (
          <Button
            style={{
              position: "absolute",
              top: 24,
              right: 16,
            }}
            onClick={() => regenerateStudy({ studyId: id })}
          >
            Regenerate study
          </Button>
        )} */}

        {/* Card Header */}
        {isEditing ? (
          /* Update Study Description Form - displayed in edit mode */
          <Spin spinning={isUpdating}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomInput
                name="description"
                control={control}
                inputProps={{
                  autoFocus: true,
                  onBlur: handleBlur,
                  status: errors?.description && "error",
                }}
                rules={{ required: "Name can't be blank", minLength: 1 }}
                errorMessage={errors.description?.message}
              />
            </form>
          </Spin>
        ) : (
          /* Description Title - displayed in read only mode */
          <Space
            align="center"
            size="middle"
            onClick={() => {
              setIsEditing(true);
              analytics.track("history:card:edit-name");
            }}
            style={{ cursor: "pointer", width: "fit-content" }}
          >
            <Typography.Title
              style={{ marginBlock: 0, fontFamily: serifFont, fontWeight: 400 }}
              level={4}
            >
              {description}
            </Typography.Title>
            <AntIcon component={() => <FontAwesomeIcon icon={faEdit} />} />
          </Space>
        )}

        {/* Study Audience */}
        <Typography.Paragraph style={{ fontSize: 14, marginBlock: 20 }}>
          <Typography.Text strong style={{ marginRight: 4 }}>
            {audienceItem.label}:
          </Typography.Text>
          <Typography.Text>{audienceItem.value}</Typography.Text>
        </Typography.Paragraph>

        {/* Study Info List */}
        <ul style={{ paddingLeft: 16 }}>
          {lisItems.map(
            (item, i) =>
              /* Study Info List Item - only displayed if the item has a value */
              !!item.value && (
                <li key={`history-card-${id}-${i}`} style={{ marginBottom: 4 }}>
                  <Typography.Text>
                    <Typography.Text strong style={{ marginRight: 4, fontSize: 12 }}>
                      {item.label}:
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: 12 }}>{item.value}</Typography.Text>
                  </Typography.Text>
                </li>
              )
          )}
        </ul>

        <div className={`mt-auto flex justify-${studyPlan ? "between" : "end"}`}>
          {/* Open study plan Link */}
          {studyPlan && (
            <Typography.Link
              style={{ cursor: "pointer" }}
              type="secondary"
              underline
              href={`${PLANS_ROUTE.getHref(studyPlan.id)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open {studyPlan.name}
            </Typography.Link>
          )}
          {/* Delete study Link */}
          <Typography.Text
            style={{ cursor: "pointer" }}
            type="secondary"
            underline
            onClick={() => setShowDeleteModal(true)}
          >
            Delete study
          </Typography.Text>
        </div>
      </Card>
    </>
  );
};

export default HistoryCard;
